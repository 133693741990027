define("ember-composable-helpers/helpers/take", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.take = take;
  function take([takeAmount, array]) {
    return (0, _asArray.default)(array).slice(0, takeAmount);
  }
  var _default = (0, _helper.helper)(take);
  _exports.default = _default;
});