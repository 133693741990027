define("ember-composable-helpers/helpers/compact", ["exports", "@ember/component/helper", "@ember/utils", "@ember/array"], function (_exports, _helper, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compact = compact;
  _exports.default = void 0;
  function compact([value]) {
    let array;
    if (Array.isArray(value) || (0, _array.isArray)(value)) {
      array = value;
    } else {
      array = [value];
    }
    return array.filter(item => (0, _utils.isPresent)(item));
  }
  var _default = (0, _helper.helper)(compact);
  _exports.default = _default;
});