define("ember-cli-string-helpers/helpers/w", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.w = w;
  function w([...wordStrings]) {
    return wordStrings.map(_string.w).reduce((words, moreWords) => {
      return words.concat(moreWords);
    }, []);
  }
  var _default = (0, _helper.helper)(w);
  _exports.default = _default;
});