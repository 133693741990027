define("ember-cli-string-helpers/helpers/truncate", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncate = truncate;
  function truncate([string, characterLimit = 140, useEllipsis = true]) {
    let limit = useEllipsis ? characterLimit - 3 : characterLimit;
    if ((0, _template.isHTMLSafe)(string)) {
      string = string.string;
    }
    if (string && string.length > limit) {
      return useEllipsis ? `${string.substring(0, limit)}...` : string.substring(0, limit);
    } else {
      return string;
    }
  }
  var _default = (0, _helper.helper)(truncate);
  _exports.default = _default;
});