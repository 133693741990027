define("ember-composable-helpers/helpers/union", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.union = union;
  function union([...arrays]) {
    let items = [].concat(...arrays);
    return items.filter((value, index, array) => (0, _asArray.default)(array).indexOf(value) === index);
  }
  var _default = (0, _helper.helper)(union);
  _exports.default = _default;
});