define("ember-composable-helpers/helpers/queue", ["exports", "@ember/component/helper", "ember-composable-helpers/utils/is-promise"], function (_exports, _helper, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.queue = queue;
  function queue(actions = []) {
    return function (...args) {
      let invokeWithArgs = function (acc, curr) {
        if ((0, _isPromise.default)(acc)) {
          return acc.then(() => curr(...args));
        }
        return curr(...args);
      };
      return actions.reduce((acc, curr, idx) => {
        if (idx === 0) {
          return curr(...args);
        }
        return invokeWithArgs(acc, curr);
      }, undefined);
    };
  }
  var _default = (0, _helper.helper)(queue);
  _exports.default = _default;
});