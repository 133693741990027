define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faBars = {
    prefix: 'far',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM448 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24z"]
  };
  var faMagnifyingGlass = {
    prefix: 'far',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"]
  };
  var faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };
  var faTimes = faXmark;

  exports.faBars = faBars;
  exports.faMagnifyingGlass = faMagnifyingGlass;
  exports.faTimes = faTimes;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
