define("ember-composable-helpers/helpers/group-by", ["exports", "@ember/component/helper", "@ember/object", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _object, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.groupBy = groupBy;
  function groupBy([byPath, array]) {
    let groups = {};
    (0, _asArray.default)(array).forEach(item => {
      let groupName = (0, _object.get)(item, byPath);
      let group = groups[groupName];
      if (!Array.isArray(group)) {
        group = [];
        groups[groupName] = group;
      }
      group.push(item);
    });
    return groups;
  }
  var _default = (0, _helper.helper)(groupBy);
  _exports.default = _default;
});